import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import VideoModal from "../../../components/video-modal/video-modal";
import FaqAccordion from "../../../components/faq/faq-accordion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import MobileAppDownloadBtn from "../../../components/mobile-online-banking/mobile-app-download-btn";
import ContactInfo from "../../../components/contact-info/contact-info";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

import ZelleHomescreen from "../../../images/zelle/wafd-mobile-zelle-103124.png";
import ZelleMoveMoney from "../../../images/zelle/wafd-mobile-move-money-103124.png";
import ZelleSendMoney from "../../../images/zelle/wafd-mobile-zelle-send-103124.png";

const Zelle = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/mobile-banking-app/zelle/hero-zelle-01032023-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/personal-banking/mobile-banking-app/zelle/hero-zelle-01032023-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/personal-banking/mobile-banking-app/zelle/hero-zelle-01032023-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/personal-banking/mobile-banking-app/zelle/hero-zelle-01032023-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/personal-banking/mobile-banking-app/zelle/hero-zelle-01032023-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/personal-banking/mobile-banking-app/zelle/hero-zelle-01032023-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/mobile-banking-app/zelle/hero-zelle-01032023-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
      zelleFriends: file(relativePath: { eq: "zelle/thumbnail-zelle-friends-020724.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      zelleWafdLogo: file(relativePath: { eq: "third-party/zelle/zelle_standard_logo_lockup.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const heroChevronData = {
    id: "zelle-hero",
    ...getHeroImgVariables(imgData),
    altText: "Woman using her cellphone while out in the city",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            text: "Send Money in the Moment with Zelle<sup>&reg;</sup>",
            class: "text-white"
          }
        }
      ]
    }
  };

  const title = "Zelle - Send and Receive Money";
  const description =
    "With Zelle you can connect your bank account and send money with just an email address or mobile number.";

  const SEOData = {
    title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/mobile-banking-app/zelle"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-zelle-01032023-250.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/mobile-banking-app",
      title: "Mobile-banking-app"
    },
    {
      id: 3,
      title: "Zelle Payments",
      active: true
    }
  ];

  const faqAccordionData = {
    id: "zelle-faq-accordion",
    title: "Top Zelle<sup>&reg;</sup> questions",
    seeMoreFaqsText: "See all Zelle<sup>&reg;</sup> FAQs",
    seeMoreFaqsId: "zelle-see-more-faqs-link",
    seeMoreFaqsLink: "/personal-banking/mobile-banking-app/zelle-faq",
    faqCategoryNames: ["Zelle Personal"],
    sectionClass: "pt-0",
    showContactBar: false
  };

  const [showVideo, setShowVideo1] = useState(false);
  const handleCloseVideo = () => setShowVideo1(false);
  const handleShowVideo = (e) => {
    e.preventDefault();
    setShowVideo1(true);
  };

  const zelleVideoData = {
    id: "zelle-payments-video",
    frameClass: "w-100 h-100",
    VimeoID: "657955080",
    Title: "How to Send and Receive Zelle Payments | WaFd Bank",
    modalSize: "lg",
    show: showVideo,
    handleClose: handleCloseVideo
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <VideoModal {...zelleVideoData} />
      <section className="container">
        <div className="row">
          <div className="d-sm-none d-md-block col-md-4 col-xl-4 text-center my-3 my-md-0">
            <img
              src={ZelleHomescreen}
              alt="WaFd Bank mobile application Zelle landing screen."
              style={{ maxWidth: "100%" }}
            />
          </div>
          <div className="col-md-8 col-xl-8">
            <h1>
              Zelle<sup>&reg;</sup>
            </h1>
            <h2 className="text-green-60">Right from your WaFd Bank Mobile App</h2>
            <h3>
              Zelle<sup>&reg;</sup> is fast, safe and easy way to send money typically in minutes<sup>1</sup> to your
              friends and family, right from the WaFd Bank mobile app.
            </h3>
            <h3>
              When you use the WaFd Bank mobile banking app, Zelle<sup>&reg;</sup> is already available on your phone.{" "}
              Zelle<sup>&reg;</sup> makes it easy to send to or receive money from people you trust &ndash; no matter
              where they bank.<sup>2</sup>
            </h3>
            <h4>
              Have a question about Zelle<sup>&reg;</sup>?{" "}
              <Link
                to="/personal-banking/mobile-banking-app/zelle-faq"
                id="zelle-faq-internal-link"
                className="font-weight-bold text-decoration-none"
              >
                Visit our Zelle<sup>&reg;</sup> FAQs
                <FontAwesomeIcon icon={["fas", "arrow-right"]} className="ml-1" />
              </Link>
            </h4>

            <h4 className="text-success text-larger font-weight-bold">Get the WaFd Bank mobile app</h4>
            <div className="row">
              <div className="col-3 col-lg-auto d-none d-md-block">
                <StaticImage
                  src="../../../images/wafd-mobile-app-qr-code.png"
                  alt="WaFd App QR Code"
                  placeholder="blurred"
                  quality="100"
                />
                <p className="text-gray-60 text-sm text-center">Scan to download</p>
              </div>
              <div className="col-auto mb-3 mb-sm-0">
                <MobileAppDownloadBtn
                  idName="google-play-store"
                  storeBtn="google"
                  url="https://play.google.com/store/apps/details?id=com.washingtonfederal.mobilebanking&amp;hl=en_US"
                />
                <p className="text-gray-60 text-sm text-center">For Android devices</p>
              </div>
              <div className="col-auto mb-3 mb-sm-0">
                <MobileAppDownloadBtn
                  idName="apple-store"
                  storeBtn="apple"
                  url="https://apps.apple.com/us/app/wafd-bank/id1054549841"
                />
                <p className="text-gray-60 text-sm text-center">For Apple devices</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light">
        <div className="container">
          <h2 className="text-center pb-4 font-weight-bold">Send money the way it was meant to be sent</h2>
          <div className="row">
            <div className="col-md-4 text-center">
              <h3 className="text-success font-weight-bold">EASY</h3>
              <h4 id="zelle-benefit-fast">
                Your customers can pay you with Zelle<sup>&reg;</sup> right from their banking app, allowing you to
                receive payments while on the go with no extra hardware or trip to the bank.
              </h4>
            </div>
            <div className="col-md-4 text-center border-1 border-left border-right border-gray-30">
              <h3 className="text-success font-weight-bold">FAST</h3>
              <h4 id="zelle-benefit-safe">
                Enhance cash flow. No need to wait for a check to clear, payments are sent directly to your bank
                account, typically within minutes.<sup>1</sup>
              </h4>
            </div>
            <div className="col-md-4 text-center">
              <h3 className="text-success font-weight-bold">SAFE</h3>
              <h4 id="zelle-benefit-easy">
                No need to provide your account information to send and receive payments<sup>1</sup> with Zelle
                <sup>&reg;</sup>.
              </h4>
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <div className="row">
          <div className="col-md-4 pb-4 pb-md-0">
            <h3 className="font-weight-semi-bold">
              Get started with Zelle<sup>&reg;</sup> in your WaFd Bank app
            </h3>
            <ol>
              <li id="start-zelle-list-item-1">Log into the WaFd Bank mobile app</li>
              <li id="start-zelle-list-item-2">Tap "Move Money" from the menu at the bottom of the screen</li>
              <li id="start-zelle-list-item-3">Tap "Send with Zelle"</li>
              <li id="start-zelle-list-item-4">Enroll with your U.S. mobile number or email address to get started</li>
            </ol>
            <h3 className="font-weight-semi-bold">Send</h3>
            <h4>
              Select a contact or type in the U.S. mobile number or email address of the person with whom you'd like to
              send money.
            </h4>
            <p className="text-success font-weight-bold mb-0">Pay it safe</p>
            <p>
              Remember to only send money to people you know and trust. Enter the amount you'd like to send and, if you
              want, add a note. Tap send and you're done.
            </p>
            <p className="mb-0">
              <a
                href="/#"
                id="zelle-send-safely-internal-link"
                onClick={handleShowVideo}
                role="button"
                className="text-decoration-none"
              >
                <FontAwesomeIcon icon={["fas", "play-circle"]} className="mr-2" />
                Zelle<sup>&reg;</sup> | Sending Money Safely
              </a>
            </p>
          </div>
          <div className="col-md-4 pb-4 pb-md-0 text-center">
            <img
              src={ZelleMoveMoney}
              alt="WaFd Bank mobile application Move Money screen."
              style={{ maxWidth: "100%" }}
            />
          </div>
          <div className="col-md-4 text-center">
            <img
              src={ZelleSendMoney}
              alt="WaFd Bank mobile application Zelle Send screen."
              style={{ maxWidth: "100%" }}
            />
          </div>
        </div>
      </section>

      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pb-3 pb-lg-0">
              <GatsbyImage
                image={imgData.zelleFriends.childImageSharp.gatsbyImageData}
                alt="Four friends hanging out in a cafe and smiling"
              />
            </div>
            <div className="col-md-6">
              <h2 className="font-weight-bold mb-3">Split</h2>
              <h4>
                Zelle<sup>&reg;</sup> makes it easy to quickly split the cost of the bill with a built-in calculator.
                <sup>3</sup> Just tap the &lsquo;split&rsquo; button, select the people in your group and enter the
                total amount you want to split. Zelle<sup>&reg;</sup> sends a request to everyone for their share.
              </h4>
              <h5 className="text-success mb-0">Your friend forgot his wallet but not his phone</h5>
              <p>
                Don't worry about covering your friends. Zelle<sup>&reg;</sup> lets you request money and get paid back
                quickly.
                <sup>3</sup>
              </p>
              <h5 className="text-success mb-0">When it's time to settle-up with the sitter</h5>
              <p className="mb-0">
                Move money from your account to theirs typically within minutes.<sup>1</sup>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <FaqAccordion {...faqAccordionData} />
        <div className="row">
          <div className="col-md-5">
            <h3>
              Zelle<sup>&reg;</sup> transfer limits
            </h3>
            <h4>
              Zelle<sup>&reg;</sup> includes both daily and monthly transfer limits. Transfers are limited by amount and
              number.
            </h4>
          </div>
          <div className="col-md-7">
            <table className="table table-bordered table-fixed mb-0">
              <thead className="thead-green">
                <tr>
                  <th className="w-75">Transfer Details</th>
                  <th className="w-25">Limit</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td id="zelle-transfer-details-item-1">Maximum number of transfers per day</td>
                  <td id="zelle-transfer-limit-item-1" className="text-left">
                    15
                  </td>
                </tr>
                <tr>
                  <td id="zelle-transfer-details-item-2">
                    Maximum number of transfers per month (Rolling 30 day window)
                  </td>
                  <td id="zelle-transfer-limit-item-2" className="text-left">
                    30
                  </td>
                </tr>
                <tr>
                  <td id="zelle-transfer-details-item-3">Minimum transfer amount</td>
                  <td id="zelle-transfer-limit-item-3" className="text-left">
                    $5
                  </td>
                </tr>
                <tr>
                  <td id="zelle-transfer-details-item-4">Daily maximum amount for same day transfers</td>
                  <td id="zelle-transfer-limit-item-4" className="text-left">
                    $600
                  </td>
                </tr>
                <tr>
                  <td id="zelle-transfer-details-item-5">
                    Monthly maximum amount for same day transfers (Rolling 30 day window)
                  </td>
                  <td id="zelle-transfer-limit-item-5" className="text-left">
                    $600
                  </td>
                </tr>
                <tr>
                  <td id="zelle-transfer-details-item-6">Daily maximum amount for next day or standard transfers</td>
                  <td id="zelle-transfer-limit-item-6" className="text-left">
                    $3,000
                  </td>
                </tr>
                <tr>
                  <td id="zelle-transfer-details-item-7">
                    Monthly maximum amount for next day or standard transfers (Rolling 30 day window)
                  </td>
                  <td id="zelle-transfer-limit-item-7" className="text-left">
                    $10,000
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <ContactInfo />

      <section className="container text-muted text-sm">
        <GatsbyImage
          image={imgData.zelleWafdLogo.childImageSharp.gatsbyImageData}
          alt="WaFd Bank's and Zelle's logos."
          className="mb-3 pb-lg-4"
          style={{ width: "236px" }}
          objectFit="contain"
          placeholder="blurred"
        />
        <p id="zelle-disclaimer-item-1">
          <sup>1</sup> Transactions typically occur in minutes when the recipient's email address or U.S. mobile number
          is already enrolled with Zelle<sup>&reg;</sup>. Additional restrictions apply; for more information please
          review our{" "}
          <a
            target="_blank"
            href="/documents/online-and-mobile-banking-service-agreement.pdf"
            id="zelle-online-mobile-agreements-internal-link"
          >
            Personal Online and Mobile Banking Agreement
          </a>
          .
        </p>
        <p id="zelle-disclaimer-item-2">
          <sup>2</sup> Must have a bank account in the U.S. to use Zelle<sup>&reg;</sup>.
        </p>
        <p id="zelle-disclaimer-item-3">
          <sup>3</sup> In order to send payment requests or split payment requests to a U.S. mobile number, the mobile
          number must already be enrolled in Zelle<sup>&reg;</sup>.
        </p>
        <p id="zelle-disclaimer-item-4">
          <sup>4</sup> Mobile carrier fees may apply.
        </p>
        <p id="zelle-disclaimer-item-5">
          Zelle<sup>&reg;</sup> and the Zelle<sup>&reg;</sup> related marks are wholly owned by Early Warning Services,
          LLC and are used herein under license.
        </p>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default Zelle;
